import React from 'react'

import image1 from '../img/gallery/image_1.jpg'
import imageThumb1 from '../img/gallery/thumbs/image_1_thumb.jpg'
import image2 from '../img/gallery/image_2.jpg'
import imageThumb2 from '../img/gallery/thumbs/image_2_thumb.jpg'
import image3 from '../img/gallery/image_3.jpg'
import imageThumb3 from '../img/gallery/thumbs/image_3_thumb.jpg'
import image4 from '../img/gallery/image_4.jpg'
import imageThumb4 from '../img/gallery/thumbs/image_4_thumb.jpg'
import image5 from '../img/gallery/image_5.jpg'
import imageThumb5 from '../img/gallery/thumbs/image_5_thumb.jpg'
import image6 from '../img/gallery/image_6.jpg'
import imageThumb6 from '../img/gallery/thumbs/image_6_thumb.jpg'
import image7 from '../img/gallery/image_7.jpg'
import imageThumb7 from '../img/gallery/thumbs/image_7_thumb.jpg'
import image8 from '../img/gallery/image_8.jpg'
import imageThumb8 from '../img/gallery/thumbs/image_8_thumb.jpg'
import image9 from '../img/gallery/image_9.jpg'
import imageThumb9 from '../img/gallery/thumbs/image_9_thumb.jpg'
import image10 from '../img/gallery/image_10.jpg'
import imageThumb10 from '../img/gallery/thumbs/image_10_thumb.jpg'
import image11 from '../img/gallery/image_11.jpg'
import imageThumb11 from '../img/gallery/thumbs/image_11_thumb.jpg'
import image12 from '../img/gallery/image_12.jpg'
import imageThumb12 from '../img/gallery/thumbs/image_12_thumb.jpg'
import image13 from '../img/gallery/image_13.jpg'
import imageThumb13 from '../img/gallery/thumbs/image_13_thumb.jpg'
import image14 from '../img/gallery/image_14.jpg'
import imageThumb14 from '../img/gallery/thumbs/image_14_thumb.jpg'
import image15 from '../img/gallery/image_15.jpg'
import imageThumb15 from '../img/gallery/thumbs/image_15_thumb.jpg'
import image16 from '../img/gallery/image_16.jpg'
import imageThumb16 from '../img/gallery/thumbs/image_16_thumb.jpg'
import image17 from '../img/gallery/image_17.jpg'
import imageThumb17 from '../img/gallery/thumbs/image_17_thumb.jpg'
import image18 from '../img/gallery/image_18.jpg'
import imageThumb18 from '../img/gallery/thumbs/image_18_thumb.jpg'
import Layout from '../components/layout'

class GalleryImage extends React.Component {
  render() {
    const thumbImage = this.props.thumb
    const fullImage = this.props.full
    const description = this.props.description || ''

    return (
      <div className="box thumb">
        <a href={fullImage} className="fancybox" rel="gallery">
          <img className="" src={thumbImage} alt={description} width="200" height="200"/>
        </a>
      </div>
    )
  }
}

const Gallery = () => (
  <Layout>
    <div className="box">
      <h2 className="stripe">Our Services</h2>

      <GalleryImage thumb={imageThumb1} full={image1}/>
      <GalleryImage thumb={imageThumb2} full={image2}/>
      <GalleryImage thumb={imageThumb3} full={image3}/>
      <GalleryImage thumb={imageThumb4} full={image4}/>
      <GalleryImage thumb={imageThumb5} full={image5}/>
      <GalleryImage thumb={imageThumb6} full={image6}/>
      <GalleryImage thumb={imageThumb7} full={image7}/>
      <GalleryImage thumb={imageThumb8} full={image8}/>
      <GalleryImage thumb={imageThumb9} full={image9}/>
      <GalleryImage thumb={imageThumb10} full={image10}/>
      <GalleryImage thumb={imageThumb11} full={image11}/>
      <GalleryImage thumb={imageThumb12} full={image12}/>
      <GalleryImage thumb={imageThumb13} full={image13}/>
      <GalleryImage thumb={imageThumb14} full={image14}/>
      <GalleryImage thumb={imageThumb15} full={image15}/>
      <GalleryImage thumb={imageThumb16} full={image16}/>
      <GalleryImage thumb={imageThumb17} full={image17}/>
      <GalleryImage thumb={imageThumb18} full={image18}/>

      <br className="clear"/>
    </div>
  </Layout>
)

export default Gallery
